<template>
  <div class="details">
   		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="@/assets/images/details/dengbaoceping1.jpg" alt="" srcset="">
                </div>
				<div class="con_top_right">
					<h2>等保测评+过证安全产品</h2>
					<p class="new_tit"> 网络安全等级保护是国家网络安全保障的基本制度、基本策略、基本方法。开展网络安全等级保护工作 是保护信息化发展、维护网络安全的根本保障，是网络安全保障工作中国家意志的体现。</p>
                    <div class="pri_box">
					    <p class="title">产品价格<span class="price">{{price}}</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                              产品套餐包括：等保定级备案、专家评审、等保测评、能达到等保下证要求的安全防护产品套件及配套技术服务。
                            </p>
                        </div>
					          </div>
                    <div class="box_ti">
					 <div class="ti_left">
                            <p>地</p><p>区</p>
                        </div>
						<select @click="shen($event)" >
							<option v-for="(item,index) in oppos" :key="index" :value="index">{{item.title}}</option>
						</select>
					</div>
					<div class="box_ti">
						 <div class="ti_left">
                            <p>分</p><p>类</p>
                        </div>
						<div class="box_fenlei">
							<span v-for="(item,index) in lists" :key="index" :class="{aa:index==num}" @click="current(index)">{{item.title}}</span>
						</div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>

    <div class="conten_body">
      <div class="conten_left">
        <ul class="nav_list"  >
          <li v-for="(thistype,key) in allDescribelist" v-bind:key=key v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName&&(allDescribelist[key-1].thisdesType.desTypeName.trim()!=''))"><a  href="" ref="spans" v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName&&(allDescribelist[key-1].thisdesType.desTypeName.trim()!=''))"  :style="{color: activeStep === index ? '#1987e1' : '#000000'}" @click="jump(key);returnFilePosition;">{{ thistype.thisdesType.desTypeName.trim() }}</a></li>
           <li><a class="a_active" href="">产品介绍</a></li>
           <li><a  href="">服务流程</a></li>
           <li><a href="">服务优势</a></li>
           <li><a href="">常见问题</a></li>
           <!-- <li><a href="">服务流程</a></li> -->
           <!-- <li><a  href="">服务流程</a></li> -->
           <!-- <li><a href="">服务优势</a></li> -->
           <!-- <li><a href="">常见问题</a></li> -->
        </ul>
        <div class="result" @scroll="onScroll">

          <div class="evaluation">
            <div class="evaluation_content">
              <div class="img">
                <img src="@/assets/images/details/01.png" alt="">
              </div>
              <div class="evaluation_right">
                <p>协助客户按照有关管理规范和技术标准，将信息系统评定为等保二级系统后，协助客户进行等保测评工作，并保障顺利通过等保测评获得测评报告。         </p>
              </div>
            </div>
          </div>

          <div class="brief">
            <div class="title">
              <h3>等级保护简介</h3>
            </div>
            <div class="brief_content">
              <div class="brief_text">
                <div class="brief_left">
                  <p>网络安全等级保护是国家网络安全保障的基本制度、基本策略、基本方法。开展网络安全等级保护工作是保护信息化发展、维护网络安全的根本保障，是网络安全保障工作中国家意志的体现。网络安全等级保护工作包括定级、备案、建设整改、等级测评、监督检查五个阶段。</p>
                </div>
                <div class="img">
                  <img src="@/assets/images/details/02.png" alt="">
                </div>
              </div>
              <div class="brief_img">
                <img src="@/assets/images/details/03.jpg" alt="">
              </div>
            </div>
          </div>

          <div class="divide">
            <div class="title">
              <h3>等保定级划分</h3>
            </div>
            <div class="divide_content">
              <h4>定级分化</h4>
              <div class="img_1">
                <img src="@/assets/images/details/8.jpg" alt="">
              </div>
              <div class="img_2">
                <img src="@/assets/images/details/04.png" alt="">
              </div>
              <div class="divide_text">
                <ul>
                  <li>第一级：用户自主保护。无需备案，对测评周期无要求</li>
                  <li>第二级：指导保护级。公安部门备案，建议两年测评一次</li>
                  <li>第三级：监督保护级。公安部门备案，要求每年测评一次</li>
                  <li>第四级：强制保护级。公安部门备案，要求半年一次</li>
                  <li>第五级：强制保护级。公安部门备案，依据特殊安全需求进行</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="when">
            <div class="title">
              <h3>哪些行业涉及办理等级保护</h3>
            </div>
            <div class="when_img">
              <img src="@/assets/images/details/9.jpg" alt="">
            </div>
          </div>

          <div class="develop">
            <div class="title">
              <h3>业务开展的必要性</h3>
            </div>
            <div class="develop_content">
              <div class="left">
                <ul>
                  <li>
                    <img src="@/assets/images/details/7.jpg" alt="">
                  </li>
                  <li>
                    <img src="@/assets/images/details/6.jpg" alt="">
                  </li>
                  <li>
                    <img src="@/assets/images/details/5.jpg" alt="">
                  </li>
                </ul>
              </div>
              <div class="right">
                <ul>
                  <li>
                    <p>1.法律法规要求：</p>
                    <p>《网络安全法》明确规定信息系统运营、使用单位应当按照网络安全等级保护制度要求，履行安全保护义务，如拒不履行，将会受到相应处罚。</p>
                  </li>
                  <li>
                    <p>2.行业准入要求：</p>
                    <p>在金融、电力、广电、医疗、教育行业，主管单位明确要求企业开展等级保护工作。</p>
                  </li>
                  <li>
                    <p>3.企业系统安全需求：</p>
                    <p>开展等级保护工作可以发现企业本身信息系统存在的安全隐患和不足，通过安全整改提升信息系统的信息安全防护能力。</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="profit">
            <div class="title">
              <h3>客户收益</h3>
            </div>
            <div class="profit_img">
              <img src="@/assets/images/details/11.jpg" alt="">
            </div>
          </div>

          <div class="detailed">
            <div class="title">
              <h3>产品详细</h3>
            </div>
            <div class="detailed_img">
              <img src="@/assets/images/details/10.jpg" alt="">
            </div>
          </div>

            <div class="advantage">
              <div class="title">
                    <h3>我们的优势</h3>
              </div>
                    <div class="advantage_content">
                        <div class="content">
                            <div class="content_left">
                                <ul>
                                    <li>国家信息安全服务资质(安全工程类)</li>
                                    <li>国家信息安全风险评估资质</li>
                                    <li>国家通信网络安全服务风险评估资质</li>
                                    <li>国家信息安全应急处理服务资质</li>
                                    <li>国家信息系统安全集成服务资质</li>
                                    <li>国家信息安全漏洞库支持单位</li>
                                    <li>国际信息安全管理体系ISO 27001认证</li>
                                    <li>国际质量管理体系ISO9001认证</li>
                                    <li>国际环境管理体系ISO 14001认证</li>
                                    <li>高新技术企业</li>
                                    <li>软件企业</li>
                                    <li>企业研究开发机构证书</li>
                                </ul>
                            </div>
                            <div class="content_right">
                                <img src="@/assets/images/app/qualifications.jpg" alt="">
                            </div>
                        </div>
                        <div class="content_bottom">
                            <ul>
                                <li>1.安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</li>
                                <li>2.高效的等保通过率，帮助客户在最短的时间最高的质量最低的价格通过等保测评认证。</li>
                                <li>3.稳定的合作关系：与众多测评机构建立稳定的合作关系，深刻理解测评重点，帮助客户省时省力通过等保合规。</li>
                                <li>4.公司资质：具备国家网络信息安全主管部门颁发的权威安全认证资质及国际权威认证资质。</li>
                            </ul>
                        </div>
                    </div>
                </div>

          <div class="case">
            <div class="title">
              <h3>典型案例</h3>
            </div>
            <div class="case_text">
              <ul>
                <li>
                  <p>1.广州某某智能物流公司</p>
                  <p>需求：该公司货运平台系统等级保护测评项目(三级）</p>
                  <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，省去大部分过度设备投资，用最小最优的成本实现等保过保。</p>
                </li>
                <li>
                  <p>2.深圳市某某中学 </p>
                  <p>需求：等保二级</p>
                  <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，顺利通过等级保护测评机构的测评验收工作，取得公安部颁发的相应等保证书。</p>
                </li>
                <li>
                  <p>3.杭州某某某网络科技有限公司</p>
                  <p>需求：某某某出行系统等级保护测评项目（三级）</p>
                  <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，在安全整改时，根据实际需求和合理安排，省去大部分过度设备投资，用最小最优的成本取得三级等级保护证书。</p>
                </li>
              </ul>
            </div>
          </div>
      </div>
      </div>
    
    	<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/record/guangdong.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东省增值电信业务</p>
                                        <p> 1、服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/assessment">
                                            <img src="@/assets/images/app/008.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>APP安全能力第三方...</p>
                                        <p>App、安全评估报告...</p>
                                        <!-- <p>￥9800</p> -->
                                        <p>在线咨询</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
    </div>
  </div>
</template>

<script type="text/javascript">


export default{
  name: "index",
  
  data(){
    return{
      
      value:0,
      price:'￥39000',
      num:0,
      lists:[
          {
					title:'等保测评（二级）'
				},
				{
					title:'等保测评（三级）'
				}
      ],      
      oppos:[
				{
					title:'广东'
				},
				{
					title:'北京'
				},
				{
					title:'浙江'
				},
				{
					title:'其他'
				},
			],
      // transmitData:{
      //   title:"网络安全等级保护测评服务",
      //   content:"等级保护是我们国家的基本网络安全制度、基本国策，也是一套完整和完善的网络安全管理体系。遵循等级保护相关标准开始安全建设是目前企业单位的普遍要求，也是国家关键信息基础设施保护的基本要求",
      //   price:'0',
      //   url:require('../../assets/images/b01.jpg')
      // },
      activeStep :0,
      transmitData:{},
      allDescribelist:[]//,
      // resultDes1:[],
      // resultDes2:[],
      // resultDes3:[],
      // resultDes4:[],
      // resultDes5:[],
      // resultDes6:[],
      // resultDes7:[],
      // resultDes8:[]

    }
  },
  mounted(){


  },
  methods:{
     shen(e){
			this.value = e.target.value;
			if(e.target.value == 0){
				if(this.num == 0){
					// this.price = "￥40000"
          this.price = "4.9万"
				}if(this.num == 1){
					// this.price = "￥60000"
          this.price = "6.9万"
				}
			}
			if(e.target.value == 1){
				if(this.num == 0){
					this.price = "在线咨询"
				}if(this.num == 1){
					this.price = "在线咨询"
				}
			}if(e.target.value == 2){
					if(this.num == 0){
					this.price = "在线咨询"
				}if(this.num == 1){
					this.price = "在线咨询"
				}
			}
			if(e.target.value == 3){
					if(this.num == 0){
					this.price = "在线咨询"
				}if(this.num == 1){
					this.price = "在线咨询"
				}
			}
		},
    current(index){
			this.num = index
        console.log(this.num , this.value);
			if(this.num == 0 && this.value == 0){
				// this.price = "￥40000"
        this.price = "4.9万"
			}if(this.num == 1 && this.value == 0){
				// this.price = "￥60000"
        this.price = "6.9万"
			}if(this.num == 0 && this.value == 1){
				this.price =  "在线咨询"
			}if(this.num == 1 && this.value == 1){
				this.price =  "在线咨询"
			}if(this.num == 0 && this.value == 2){
				this.price =  "在线咨询"
			}if(this.num == 1 && this.value == 2){
				this.price =  "在线咨询"
			}
			if(this.num == 0 && this.value == 3){
				this.price =  "在线咨询"
			}
			if(this.num == 1 && this.value == 3){
				this.price =  "在线咨询"
			}

		},
    page(currentPage){
      const _this = this
      // _this.$axios.get("/menus?currentPage=" + currentPage).then(res => {
      let proId="1";
      _this.$axios.get("/ProductOne?proId=" + proId).then(res => {
        var data = res.data.data
        _this.transmitData = data
        _this.allDescribelist=data.allDescribelist;
        // var allDes =_this.allDescribelist;
        // var allDesByTypeId;
        // for(i=1;i<9;i++) {
        //   _this.allDesByTypeId=allDes.find(item => item.thisdesType.desTypeId == i);
        //   _this.allDesByTypeId.concat(_this.allDesByTypeId);
        // }

        // var i=1;
        // for(let item of response.data.result) {
        //
        // }
        //
        //
        // _this.resultDes1 = allDes.find(item => item.thisdesType.desTypeId == 1);
        // _this.resultDes2 = allDes.find(item => item.thisdesType.desTypeId == 2);
        // _this.resultDes3 = allDes.find(item => item.thisdesType.desTypeId == 3);
        // _this.resultDes4 = allDes.find(item => item.thisdesType.desTypeId == 4);
        // _this.resultDes5 = allDes.find(item => item.thisdesType.desTypeId == 5);
        // _this.resultDes6 = allDes.find(item => item.thisdesType.desTypeId == 6);
        // _this.resultDes7 = allDes.find(item => item.thisdesType.desTypeId == 7);
        // _this.resultDes8 = allDes.find(item => item.thisdesType.desTypeId == 8);
        // _this.$message({
        //   message:_this.resultDes1.desName,
        //   type: 'success',
        // });

      })
      //     function DesItem (Des,n) {
      //   // return _this.allDescribelist.find(item => item.thisdesType.desTypeId == n);
      //       return Des.find(item => item.thisdesType.desTypeId == n);
      // }

    },
    jump(index) {
      var items = document.querySelectorAll(".con_list");//scroll-item");
      for (var i = 0; i < items.length; i++) {
        if (index === i) {
          items[i].scrollIntoView({
            block: "start",//默认跳转到顶部
            behavior: "smooth"//滚动的速度
          });
        }
      }
    },
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".con_list");//".scroll-item");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
            e.target.scrollTop >=
            scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.activeStep = i;
          var evt=window.event;
          evt.preventDefault();
          break;
        }
      }
    },
    returnFilePosition(){ // vue中锚链接问题
      document.querySelector("#con_list").scrollIntoView(true);
    }

  },
  created() {
    if(this.$route.query.price == null){
      // this.price = "￥40000";
      this.price = "4.9万";
    
    }else{
      this.price = this.$route.query.price
    
    }
  }

};
</script>

<style type="text/css" lang="less" scoped>

.evaluation{
  margin-top: 50px;
  .title{
    background-color: #007CC6;
    text-align: center;
    h3{
      font-size: 31px;
      line-height: 83px;
      color: white;
    }
  }
  .evaluation_content{
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .img{
      width: 410px;
      height: 355px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .evaluation_right{
      width: 418px;
      p{
        font-size: 22px;
        line-height: 24px;
        text-indent: 2em;
        font-weight: bold;
      }
    }
  }
}
.brief{
   .title{
    background-color: #007CC6;
    text-align: center;
    h3{
      font-size: 31px;
      line-height: 83px;
      color: white;
    }
  }
  .brief_content{
    margin-top: 30px;
    .brief_text{
      display: flex;
      justify-content: space-around;
      align-items: center;
      .brief_left{
        width: 453px;
        p{
          font-size: 22px;
          line-height: 24px;
        }
      }
      .img{
        width: 300px;
        height: 223px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .brief_img{
      margin: auto;
      margin-top: 50px;
      width: 750px;
      height: 430px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.divide{
  margin-top: 30px;
   .title{
    background-color: #007CC6;
    text-align: center;
    h3{
      font-size: 31px;
      line-height: 83px;
      color: white;
    }
  }
  .divide_content{
    margin-top: 30px;
    h4{
      font-size: 22px;
      color: #007CC6;
      margin-left: 20px;
    }
    .img_1{
      width: 850px;
      height: 279px;
      margin: auto;
      margin-top: 38px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .img_2{
      width: 714px;
      height: 212px;
      margin: auto;
      margin-top: 38px;
      img{
         width: 100%;
        height: 100%;
      }
    }
    .divide_text{
      margin: auto;
      width: 594px;
      margin-top: 38px;
      ul{
        li{
          font-size: 22px;
          line-height: 24px;
           color: #007CC6;
        }
      }
    }
  }
}
.when{
  margin-top: 30px;
  .title{
    background-color: #007CC6;
    text-align: center;
    h3{
      font-size: 31px;
      line-height: 83px;
      color: white;
    }
  }
  .when_img{
    width: 860px;
    height: 538px;
    margin: auto;
    margin-top: 30px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.develop{
  margin-top: 30px;
  .title{
    background-color: #007CC6;
    text-align: center;
    h3{
      font-size: 31px;
      line-height: 83px;
      color: white;
    }
  }
  .develop_content{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .left{
      ul{
        display: flex;
        flex-direction: column;
        li{
          margin-bottom: 10px;
          width: 166px;
          height: 170px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .right{
      width: 439px;
      margin-top: 35px;
      ul{
          display: flex;
        flex-direction: column;
        li{
          margin-bottom: 80px;
          p{
            font-size: 22px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
.profit{
  .title{
    background-color: #007CC6;
    text-align: center;
    h3{
      font-size: 31px;
      line-height: 83px;
      color: white;
    }
  }
  .profit_img{
    margin-top: 20px;
    width: 100%;
    height: 466px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.detailed{
  margin-top: 30px;
  .title{
    background-color: #007CC6;
    text-align: center;
    h3{
      font-size: 31px;
      line-height: 83px;
      color: white;
    }
  }
  .detailed_img{
    width: 858px;
    height: 1116px;
    margin: auto;
    margin-top: 30px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.advantage{
    margin-top: 30px;
    .title{
        background-color: #007CC6;
    text-align: center;
    h3{
       font-size: 31px;
      line-height: 83px;
      color: white;
    }
    }
    .advantage_content{
        margin-top: 74px;
        .content{
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            .content_left{
                ul{
                    li{
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
            }
            .content_right{
                width: 313px;
                height: 296px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content_bottom{
            margin: 34px 20px;
            ul{
                li{
                    font-size: 22px;
                    line-height: 24px;
                   
                }
            }
        }
    }
}
.case{
  margin-top: 30px;
    .title{
        background-color: #007CC6;
    text-align: center;
    h3{
       font-size: 31px;
      line-height: 83px;
      color: white;
    }
    }
    .case_text{
      margin: 30px 10px 10px 20px;
      ul{
        li{
          margin-bottom: 22px;
          p{
            font-size: 22px;
            line-height: 24px;
          }
        }
      }
    }
}


.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #00A9F2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #00A9F2;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 25px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}


.details{
  width:1200px;
  margin:0 auto;

  //**/
  .conten_body{
    margin-top:10px;
    overflow: hidden;
  }
  .conten_left{
    width:880px;
    // width:1200px;
    float:left;
    background:#fff;
    box-sizing: border-box;
  }
  .nav_list{
    height:39px;
    //height:0px;
    border-bottom: 1px solid #CFCDCD;
  }
  .nav_list li{
    float:left;
    width:120px;
    text-align:center;
  }
  .nav_list li a{
    display: inline-block;
    height:39px;
    font-size: 15px;
    line-height: 39px;
    color: #007CC6;
  }
  .nav_list li  .a_active{
    color:#007CC6;
    border-bottom:1px solid #007CC6;
  }

  .contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#00A9F2;

	line-height: 18px;
	font-size:16px;
    padding:5px 0;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 35px;
    padding-right: 2px;
    font-weight: bold;
}
.pri_box  .price{
	font-size: 28px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
  margin-left: 35px;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 14px;
            line-height: 16px;
        }
    }
}
.box_ti{
   margin-top:30px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.box_ti .add{
	font-size: 15px;
	color: #474747;
	line-height: 42px;
	display: inline-block;
	width:60px;
	margin-left:20px;
	margin-right: 20px;
	text-align: center;
}
.box_ti select{
	width:112px;
	height:32px;
    border: 1px solid #CFCDCD;
}
.box_fenlei{
	display: inline-block;
}
.box_fenlei span{
    width: 112px;
	height: 32px;
	display: inline-block;
	border: 1px solid #CFCDCD;
	font-size: 13px;
	text-align: center;
	line-height: 32px;
	margin-right:19px;
	cursor: pointer;
}
.box_fenlei .aa{
  	background-color: #0099CC;
}
 
  




  .result {
    width: 100%;
    //height: 400px;
  }



}


</style>
